.info-container {
    width: 100%;
    height: auto;
}

.link-btn-container {
    border: 2px solid white;
    height: 50px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;
}

.link-btn-blue-container {
    border: 2px solid #3c3b6e;
    height: 50px;
    width: 200px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 2em;

}

.about-container {
    display: flex;
}

.about-img-container {
    flex: 60%;
    z-index: 50;
}

.about-txt-container {
    flex: 40%;
    align-items: center;
    flex-direction: column;
}

.txt-box {
    display: flex;
    flex-direction: column;
    background: white;
    width: 100%;
    height: 100%;
}

@keyframes fadein {
    0% {
        opacity: 0%;
    }
    100% {
        opacity: 100%;
    }
}

@keyframes slideLeft {
    0% {
        transform: translate(100vw, 0);
    } 50% {
        opacity: 0.75;
    } 100% {
        opacity: 1;
    }
}

@keyframes slideRight {
    0% {
        transform: translate(-100vw, 0);
    } 50% {
        opacity: 0.75;
    } 100% {
        opacity: 1;
    }
}

.slideLeft {
    animation: slideLeft;
    animation-duration: 1s;
}

.slideRight {
    animation: slideRight;
    animation-duration: 1s;
}

.fadeIn {
    animation: fadein 2s;
}

.txt {
    display: flex;
    width: 80%;
    color: #3c3b6e;
    font-style: italic;
    margin: auto;
    padding: 1em;
}

.txt-left-align {
    text-align: left;
}

.txt-right-align {
    text-align: right;
}

.about-img {
    max-width: 100%;
    height: auto;
}

.donate-container {
    display: flex;
}

.donate-img-container {
    flex: 60%;
    z-index: 50;
}

.donate-txt-container {
    flex: 40%;
    align-items: center;
    flex-direction: column;
}

.donate-img {
    max-width: 100%;
    height: auto;
}

@media only screen and (max-width: 1200px) {
    .info-container {
        clear: both;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

    .about-container {
        display: flex;
        flex-direction: column;
    }
      
    .about-txt-container {
        flex: 1;
        height: auto;
    }
    
    .about-img {
        height: auto;
        width: 100%;
    }

    .about-img-container {
        flex: 1;
        border-right: none;
    }

    .donate-container {
        display: flex;
        flex-direction: column;
    }
    
    .donate-txt-container {
        align-self: center;
        flex: 1;
        height: auto;
    }
    
    .donate-img {
        height: auto;
    }

    .donate-img-container {
        flex: 1;
        border-left: none;
    }

    .txt-box {
        display: flex;
        flex-direction: column;
        background: white;
        width: 100%;
        height: 100%;
        padding: 1em;
    }

    .txt-left-align, .txt-right-align {
        text-align: center;
    }

    .link-btn-blue-container {
        border: 2px solid #3c3b6e;
        height: 50px;
        width: 200px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: 2em;
    }

    @keyframes fadein {
        0% {
            opacity: 100%;
        }
        100% {
            opacity: 100%;
        }
    }

    .fadeIn {
        animation: fadein;
        animation-duration: 0s;
    }
}