@font-face {
  font-family: 'Gotham';
  src: url(./fonts/GothamMedium.ttf) format('truetype');
}

.App {
  text-align: center;
  box-sizing: border-box;
}

.banner-img {
  max-width: 100%;
  height: auto;
}

.banner-img-2 {
  max-width: 100%;
  height: auto;
  margin-bottom: 100px;
}

.input-container {
  display: inline-block;
  color: #3c3b6e;
  max-width: 700px;
  height: 250px;
  align-content: center;
  margin: auto;
  border: 3px solid #b22234;
  padding: 20px;
}

.input-box {
  box-shadow: none;
  border: 1px solid #3c3b6e;
}

.input-name {
  margin: 10px;
}

.input-email {
  margin: 10px;
  width: auto;
}

.submit-btn {
  background: #3c3b6e;
  color: white;
  border: none;
  height: 30px;
  width: 250px;
  font-size: 16px;
}

.submit-btn:hover {
  font-size: 17px;
  color: whitesmoke;
  height: 33px;
  width: 275px;
}

.link-btn {
  background: transparent;
  color: white;
  font-size: 18px;
  border: none;
  width: 100%;
  float: left;
  height: 100%;
  box-shadow: inset 0 0 0 0 white;
  transition: ease-out 0.75s;
}

.link-btn:hover {
  color: #3c3b6e;
}

.link-btn-bkg:hover {
  box-shadow: inset 200px 0 0 0 white;
}

.link-btn-blue {
  background: transparent;
  color: #3c3b6e;
  font-size: 18px;
  border: none;
  width: 100%;
  float: left;
  height: 100%;
  box-shadow: inset 0 0 0 0 #3c3b6e;
  transition: ease-out 0.75s;
  padding: 0;
}

.link-btn-blue:hover {
  color: white;
}

.link-btn-blue-bkg:hover {
  box-shadow: inset 200px 0 0 0 #3c3b6e;
}

.background-img {
  width: 100%;
  height: auto;
}

@media only screen and (max-width: 700px) {
  .App {
    text-align: center;
    box-sizing: border-box;
  }

  .body {
    text-align: center;
    box-sizing: border-box;
  }

  .App-logo {
    height: 40vmin;
  }

  h1 {
    font-size: 18px;
  }

  .link-btn-blue {
    background: #3c3b6e;
    color: white;
    font-size: 18px;
    border: none;
    width: 100%;
    height: 100%;
    font-family: '';
  }

  .input-container {
    color: #3c3b6e;
    max-width: 100%;
    height: auto;
    align-content: center;
    margin: auto;
    border: 3px solid #b22234;
  }

  .input-box {
    box-shadow: none;
    border: 1px solid #3c3b6e;
  }

  .input-name {
    margin: 5px;
    font-family: '';
  }

  .input-email {
    margin: 10px;
    width: auto;
    font-family: '';
  }

  .submit-btn {
    background: #3c3b6e;
    color: white;
    border: none;
    height: 30px;
    width: auto;
    margin: 5px;
    font-family: '';
  }

}

.red-info {
  color: #B22234;
  margin: 2vmax;
}

.red-info-italics {
  color:  #B22234;
  margin: 2vmax;
  font-style: italic;
}

.white-info-italics {
  color:  white;
  margin: 2vmax;
  font-style: italic;
}
